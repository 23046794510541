import { FormControl, InputLabel, MenuItem, Select, SelectChangeEvent, Stack, Switch, Typography } from '@mui/material';
import { useCallback, useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector, useCreateNewJob, useFirstFpl } from '../../../hooks';
import {
  extendedApiJobsSlice,
  FormSteps,
  JobLiteType,
  JobType,
  selectUserProfile,
  setActiveStep,
  setIsStrategiesMergeCompleted,
  useGetJobQuery,
  useGetJobsQuery,
} from '../../../reducers';
import { PartialSearch } from '../../Common';
import { staticData } from '../../../application/common';
import { useFormikContext } from 'formik';

export const JobSelection = (props: { setInitialValues: React.Dispatch<React.SetStateAction<JobType>> }) => {
  const { setInitialValues } = props;
  const dispatch = useAppDispatch();

  const { initialValues } = useFormikContext<JobType>();

  const [shouldFilterJobs, setShouldFilterJobs] = useState(false);
  const [shouldFilterJobsByStatus, setShouldFilterJobsByStatus] = useState<string>(staticData.JOB_STATUS_NO_FILTER);
  const [jobID, setJobID] = useState('');

  const user = useAppSelector(selectUserProfile);

  const { createNewJobResult, triggerCreateNewJob } = useCreateNewJob();

  const getJobsResult = useGetJobsQuery('');
  const getJobResult = useGetJobQuery(jobID, { skip: !jobID, refetchOnMountOrArgChange: true });

  const { getFirstFpl } = useFirstFpl();

  const removeJobIfDraft = useCallback(
    (job: JobType) => {
      const isJobPersistent = (job: JobType) => job.jobID && !job.isDraft;
      const updateJobsRemoveDraft = (jobToRemove: JobType) => {
        dispatch(
          extendedApiJobsSlice.util.updateQueryData('getJobs', '', (jobsDraft: JobLiteType[]) => {
            const index = jobsDraft.findIndex((jobDraft) => jobDraft.jobID === jobToRemove.jobID) || -1;
            if (index > -1) {
              jobsDraft.splice(index, 1);
            }
          }),
        );
      };
      if (!isJobPersistent(job)) updateJobsRemoveDraft(job);
    },
    [dispatch],
  );

  useEffect(() => {
    if (!jobID && getJobsResult.isSuccess && createNewJobResult.isUninitialized) triggerCreateNewJob();
  }, [createNewJobResult.isUninitialized, getJobsResult.isSuccess, jobID, triggerCreateNewJob]);

  useEffect(() => {
    if (!createNewJobResult.isUninitialized && !createNewJobResult.isLoading && createNewJobResult.data?.jobID) {
      const updateJobsAddNewDraft = (newJobDraft: JobType) => {
        dispatch(
          extendedApiJobsSlice.util.updateQueryData('getJobs', '', (jobsDraft: JobLiteType[]) => {
            jobsDraft.push(newJobDraft);
          }),
        );
      };
      const newJobDraft: JobType = {
        ...createNewJobResult.data,
        jobName: `New Job`,
        jobStatus: staticData.JOB_STATUS_NEW,
        isDraft: true,
      };
      removeJobIfDraft(initialValues);
      updateJobsAddNewDraft(newJobDraft);
      setInitialValues(newJobDraft);
      setJobID(newJobDraft.jobID);
      dispatch(setActiveStep(FormSteps.MainSelection));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [createNewJobResult, dispatch, removeJobIfDraft]);

  useEffect(() => {
    if (getJobResult.data?.jobID) {
      const updateJobsReplaceJobData = (jobToReplace: JobType) => {
        dispatch(
          extendedApiJobsSlice.util.updateQueryData('getJobs', '', (jobsDraft: JobLiteType[]) => {
            const index = jobsDraft.findIndex((jobDraft) => jobDraft.jobID === jobToReplace.jobID);
            if (index > -1) {
              jobsDraft[index] = jobToReplace;
            }
          }),
        );
      };

      const { firstFpl } = getFirstFpl({ strategies: getJobResult.data.strategies });
      const newJobData: JobType = {
        ...getJobResult.data,
        isPerformanceOnlyBook: Boolean(firstFpl?.isPerformanceOnlyBook),
      };
      setInitialValues(newJobData);
      updateJobsReplaceJobData(newJobData);
      dispatch(setIsStrategiesMergeCompleted(false));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, getJobResult.data, setInitialValues]);

  useEffect(() => {
    dispatch(setActiveStep(FormSteps.MainSelection));
  }, [initialValues.jobID, dispatch]);

  const handleJobSelection = (newValueObj: JobLiteType) => {
    removeJobIfDraft(initialValues);
    setJobID(newValueObj.jobID);
  };

  const handleFilterByStatusChange = (event: SelectChangeEvent) => {
    removeJobIfDraft(initialValues);

    setShouldFilterJobsByStatus(event.target.value);
    setJobID('');
  };

  const filteredJobs = shouldFilterJobs
    ? getJobsResult.data?.filter((job: JobLiteType) => user.email === job.createdBy)
    : getJobsResult.data;
  const filteredJobsByStatus =
    shouldFilterJobsByStatus !== 'All'
      ? filteredJobs?.filter((job: JobLiteType) => {
          if (shouldFilterJobsByStatus === `Active`) {
            return [
              staticData.jobsStatus[3],
              staticData.jobsStatus[4],
              staticData.jobsStatus[5],
              staticData.jobsStatus[7],
            ].includes(job.jobStatus);
          }
          return shouldFilterJobsByStatus === job.jobStatus;
        })
      : filteredJobs;

  return (
    <Stack marginTop={2} direction="row" spacing={1} justifyItems="self-start" style={{ width: 'calc(95% + 2rem)' }}>
      <Stack direction="row" spacing={1} sx={{ width: 'calc(80% + 3.5rem)' }}>
        <PartialSearch
          id="job-partial-search"
          label="Job"
          loading={getJobsResult.isLoading}
          onChange={handleJobSelection}
          optionKey="jobID"
          optionName="jobName"
          options={filteredJobsByStatus}
          value={jobID}
        />
        <FormControl size="small" sx={{ width: '250px' }}>
          <InputLabel>Job Status</InputLabel>
          <Select
            size="small"
            label="Job Status"
            value={shouldFilterJobsByStatus}
            onChange={handleFilterByStatusChange}
          >
            {staticData.jobsStatus.map((status: string) => (
              <MenuItem value={status} key={`jobs-status-${status}`} id={`jobs-status-${status}`}>
                {status}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Stack>
      <FormControl sx={{ width: '15rem' }}>
        <Stack direction="row" spacing={1} alignSelf="center" alignItems="center">
          <Typography>All Jobs</Typography>
          <Switch
            checked={shouldFilterJobs}
            onChange={(event, newValue: boolean) => setShouldFilterJobs(newValue)}
            inputProps={{ 'aria-label': 'ant design' }}
          />
          <Typography>My Jobs</Typography>
        </Stack>
      </FormControl>
    </Stack>
  );
};
